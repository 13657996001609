import React from "react"
import PropTypes from "prop-types"

import Seo from "./seo";
import Header from "./header"
import Footer from "./footer"

import "/src/styles/main.scss"

const Layout = ({ children, slug, seo, lang, currentId }) => {

  return (
    <div className={"site page-" + slug}>
      <Seo seo={seo} lang={lang} />
      <Header currentPage={slug} currentId={currentId} lang={lang} />
      <div className="main" role="main">
        {children}
      </div>
      <Footer lang={lang} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
