import React from "react"
//import { navigate } from "gatsby-link"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Newsletter = ({ lang }) => {

  const form = {};
  switch(lang) {
    case 'en':
      form.title = "Are you looking for interesting facts about the world of translation? Subscribe to the newsletter!";
      form.name = "Name";
      form.email = "E-mail";
      form.company = "Company / Organisation (optional)";
      form.accept = "I consent to the use and processing of my contact information to receive communications about products and services, in accordance with the Nembrini Translations Privacy Policy.";
      form.text = "You may cancel your registration at any time. For further information please read the privacy policy.";
      form.button = "Send";
      form.success = "You have subscribed to the newsletter !";
      break;
    case 'fr':
        form.title = "Vous souhaitez découvrir des faits intéressants sur le monde de la traduction ? Abonnez-vous à l’infolettre !";
        form.name = "Nom";
        form.email = "Adresse électronique";
        form.company = "Entreprise / Organisation (facultatif)";
        form.accept = "J’accepte l’utilisation et le traitement de mes coordonnées afin de recevoir des communications sur des produits et services, conformément à la Privacy Policy de Nembrini Translations.";
        form.text = "Il est possible d’annuler son adhésion à tout moment. Pour plus d’informations, veuillez lire la politique de confidentialité.";
        form.button = "Envoyer";
        form.success = "Vous êtes inscris à la newsletter !";
      break;
    case 'de':
        form.title = "Möchten Sie interessante Fakten über die Welt der Übersetzung erfahren? Abonnieren Sie den Newsletter!";
        form.name = "Name";
        form.email = "E-mail";
        form.company = "Firma / Organisation (optional)";
        form.accept = "Ich stimme der Verwendung und Verarbeitung meiner Kontaktdaten zu, um Mitteilungen über Produkte und Dienstleistungen zu erhalten, in Übereinstimmung mit der Privacy Policy von Nembrini Translations.";
        form.text = "Der Newsletter ist jederzeit kündbar. Für weitere Informationen lesen Sie bitte die Datenschutzbestimmungen.";
        form.button = "Senden";
        form.success = "Sie haben den Newsletter abonniert !";
      break;
    case 'it':
        form.title = "Vi interessa scoprire qualche curiosità sul mondo della traduzione? Abbonatevi alla newsletter!";
        form.name = "Nome";
        form.email = "E-mail";
        form.company = "Azienda / Organizzazione (facoltativo)";
        form.accept = "Acconsento all'utilizzo e al trattamento delle mie informazioni di contatto per ricevere comunicazioni su prodotti e servizi, nel rispetto della Privacy Policy di Nembrini Translations.";
        form.text = "Puoi annullare l'iscrizione in qualsiasi momento. Per ulteriori informazioni si prega di leggere la normativa sulla privacy.";
        form.button = "Invia";
        form.success = "Ti sei iscritto alla newsletter !";
      break;
    default:
      break;
  }

  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      //.then(() => navigate(form.getAttribute('action')))
      .then(() => {
        var newsletterForm = document.getElementById("newsletterForm");
        var newsletterMessage = document.getElementById("newsletterMessage");

        newsletterForm.parentNode.removeChild(newsletterForm);
        newsletterMessage.classList.add("is-show");
      })
      .catch((error) => alert(error))
  }

  return (
    <section className="newsletter">
      <div className="newsletter__main">
        <div className="newsletter__head" data-animate>
          <p>{form.title}</p>
        </div>
        <div className="newsletter__form" data-animate>
          <form id="newsletterForm" className="form" name="newsletterForm" method="post" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>
            <div className="form__field u-hidden">
              <input type="hidden" name="form-name" value="newsletterForm" />
              <label>
                Don’t fill this out: <input name="bot-field" onChange={handleChange} />
              </label>
            </div>
            <div className="form__field form__field--50">
              <label className="form__label" htmlFor="name">{form.name}</label>
              <input className="form__input" type="text" id="name" name="name" onChange={handleChange} required />
            </div>
            <div className="form__field form__field--50">
              <label className="form__label" htmlFor="email">{form.email}</label>
              <input className="form__input" type="email" id="email" name="email" onChange={handleChange} required />
            </div>
            <div className="form__field">
              <label className="form__label" htmlFor="company">{form.company}</label>
              <input className="form__input" type="text" id="company" name="company" onChange={handleChange} />
            </div>
            <div className="form__field">
              <input className="form__checkbox" type="checkbox" id="accept" name="accept" onChange={handleChange} required />
              <label className="form__label" htmlFor="accept">{form.accept}</label>
            </div>
            <div className="form__field">
              <p>{form.text}</p>
            </div>
            <div className="form__field form__field--submit">
              <button type="submit" className="btn btn--secondary">{form.button}</button>
            </div>
          </form>
        </div>
        <div id="newsletterMessage" className="newsletter__message">
          <p>{form.success}</p>
        </div>
      </div>
    </section>
  );
}

export default Newsletter