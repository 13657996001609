import React, { useState } from "react"
import { Link } from "gatsby"

const LangMenu = ({ lang, urlEn, urlFr, urlDe, urlIt }) => {

  console.log(urlEn);
  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
  };

  return (
    <div className={isActive ? "lang-menu" : "lang-menu is-open"}>
      <button className="lang-menu__button" onClick={handleToggle}><span>{lang}</span></button>
      <ul className="lang-menu__list">
        <li className="lang-menu__item">
          <Link className="lang-menu__link" to={urlEn}>English</Link>
        </li>
        <li className="lang-menu__item">
          <Link className="lang-menu__link" to={urlFr}>French</Link>
        </li>
        <li className="lang-menu__item">
          <Link className="lang-menu__link" to={urlDe}>German</Link>
        </li>
        <li className="lang-menu__item">
          <Link className="lang-menu__link" to={urlIt}>Italian</Link>
        </li>
      </ul>
    </div>
  );
}

export default LangMenu