import React from "react"

const Button = ({ label, url, className }) => {
  return (
    <a href={url} className={"btn " + className}>
      <span>{label}</span>
    </a>
  );
}

export default Button