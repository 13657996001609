import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import Navigation from "./navigation"
import LangMenu from "./lang-menu"

const Header = ({ currentPage, currentId, lang }) => {
  const data = useStaticQuery(query);


  let urlEn = '/en',
      urlFr = '/fr',
      urlDe = '/de',
      urlIt = '/it';

  switch(currentId) {
    case '1':
      urlEn = '/en';
      urlFr = '/fr';
      urlDe = '/de';
      urlIt = '/it';
      break;
    case '2':
      urlEn = '/en' + data.strapiConfiguration.navigation[0].url_en;
      urlFr = '/fr' + data.strapiConfiguration.navigation[0].url_fr;
      urlDe = '/de' + data.strapiConfiguration.navigation[0].url_de;
      urlIt = '/it' + data.strapiConfiguration.navigation[0].url_it;
      break;
    case '3':
      urlEn = '/en' + data.strapiConfiguration.navigation[1].url_en;
      urlFr = '/fr' + data.strapiConfiguration.navigation[1].url_fr;
      urlDe = '/de' + data.strapiConfiguration.navigation[1].url_de;
      urlIt = '/it' + data.strapiConfiguration.navigation[1].url_it;
      break;
    default:
      urlEn = '/en';
      urlFr = '/fr';
      urlDe = '/de';
      urlIt = '/it';
      break;
  }

  return (
    <header className="header" role="banner">
      <div className="header__main">
        <div className="header__logo" data-animate>
          <h1 className="header__title"><Link to={'/' + lang}>{data.strapiConfiguration.sitename}</Link></h1>
        </div>
        <div className="header__navigation" data-animate>
          <div className="header__navigation-inner">
            <Navigation list={data.strapiConfiguration.navigation} currentPage={currentPage} lang={lang} />
            <LangMenu lang={lang} urlEn={urlEn} urlFr={urlFr} urlDe={urlDe} urlIt={urlIt} />
          </div>
        </div>
      </div>
    </header>
  );
}

const query = graphql`
  query {
    strapiConfiguration {
      sitename
      navigation {
        label_en
        url_en
        label_fr
        url_fr
        label_de
        url_de
        label_it
        url_it
      }
    }
  }
`;

export default Header