import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Button from "../ui/button"
import Newsletter from "../section/newsletter"

const Footer = ({ lang }) => {
  const data = useStaticQuery(query);

  var text_footer = null;

  switch(lang) {
    case 'en':
      text_footer = data.strapiConfiguration.text_footer_en;
      break;
    case 'fr':
      text_footer = data.strapiConfiguration.text_footer_fr;
      break;
    case 'de':
      text_footer = data.strapiConfiguration.text_footer_de;
      break;
    case 'it':
      text_footer = data.strapiConfiguration.text_footer_it;
      break;
    default:
      break;
  }

  return (
    <>
      <Newsletter lang={lang} />
      <footer className="footer">
        <div className="footer__main">
          <div className="footer__text">
            <p dangerouslySetInnerHTML={{
              __html: text_footer
            }} />
          </div>
          <div className="footer__bar">
            <div className="footer__bar-left">
              <p>
                <span>&copy; 2021 - Nembrini Translations,</span> <a href="/NNE_website_privacy-policy.pdf" target="_blank" rel="noreferrer">Privacy Policy</a>
                <a href="https://medium.com/@nina.nembrini" className="link-medium" target="_blank" rel="noreferrer">Medium</a>
              </p>
            </div>
            <div className="footer__bar-right">
              <Button 
                className="gradient"
                label={data.strapiConfiguration.email_footer} 
                url={'mailto:' + data.strapiConfiguration.email_footer}
              />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

const query = graphql`
  query {
    strapiConfiguration {
      text_footer_en
      text_footer_fr
      text_footer_de
      text_footer_it
      email_footer
    }
  }
`;

export default Footer