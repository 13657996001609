import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ seo = {}, lang }) => {
  const { strapiConfiguration } = useStaticQuery(query);
  const { sitename } = strapiConfiguration;
  const fullSeo = {};
  switch(lang) {
    case 'en':
      fullSeo.metaTitle = seo.meta_title_en;
      fullSeo.metaDescription = seo.meta_description_en;
      break;
    case 'fr':
      fullSeo.metaTitle = seo.meta_title_fr;
      fullSeo.metaDescription = seo.meta_description_fr;
      break;
    case 'de':
      fullSeo.metaTitle = seo.meta_title_de;
      fullSeo.metaDescription = seo.meta_description_de;
      break;
    case 'it':
      fullSeo.metaTitle = seo.meta_title_it;
      fullSeo.metaDescription = seo.meta_description_it;
      break;
    default:
      break;
  }

  // Merge default and page-specific SEO values
  //const fullSeo = { ...seo };

  const getMetaTags = () => {
    const tags = [];

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: sitename + ' | ' + fullSeo.metaTitle,
        },
        {
          name: "twitter:title",
          content: sitename + ' | ' + fullSeo.metaTitle,
        }
      );
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: "description",
          content: fullSeo.metaDescription,
        },
        {
          property: "og:description",
          content: fullSeo.metaDescription,
        },
        {
          name: "twitter:description",
          content: fullSeo.metaDescription,
        }
      );
    }
    //if (fullSeo.shareImage) {
      const imageUrl = "https://nembrini-translations.netlify.app/nembrini-share.jpg";
      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: imageUrl,
        }
      );
    //}
    if (fullSeo.article) {
      tags.push({
        property: "og:type",
        content: "article",
      });
    }
    tags.push({ name: "twitter:card", content: "summary_large_image" });

    return tags;
  };

  const metaTags = getMetaTags();

  return (
    <Helmet
      title={fullSeo.metaTitle}
      titleTemplate={`%s | ${sitename}`}
      meta={metaTags}
      htmlAttributes={{
        lang: `${lang}`,
      }}
    />
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

const query = graphql`
  query {
    strapiConfiguration {
      sitename
    }
  }
`;