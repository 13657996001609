import React from "react"
import { Link } from "gatsby"

const Navigation = ({ list, currentPage, lang }) => {

  const newList = [];
  list.forEach((item) => {
    switch(lang) {
      case 'en':
        newList.push({
          label: item.label_en,
          url: item.url_en
        });
        break;
      case 'fr':
        newList.push({
          label: item.label_fr,
          url: item.url_fr
        });
        break;
      case 'de':
        newList.push({
          label: item.label_de,
          url: item.url_de
        });
        break;
      case 'it':
        newList.push({
          label: item.label_it,
          url: item.url_it
        });
        break;
      default:
        break;
    }
  });

  return (
    <ul className="navigation">
      { newList.map((item, index) => (
        <li key={index} className={'navigation__item' + (item.url === '/' + currentPage ? ' is-active' : '')}>
          <Link className="navigation__link" to={'/' + lang + item.url}>{item.label}</Link>
        </li>
      ))}
    </ul>
  );
}

export default Navigation